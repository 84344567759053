import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Chip, Avatar } from '@material-ui/core';

const StyledChip = withStyles(theme => ({
	root: {
		backgroundColor: theme.palette.altBackground.main,
		border: '1px solid rgba(255, 255, 255, 0.12)',
		alignItems: 'center'
	}
}))(Chip);

export default function SkillChip(props) {
	const { label, avatar} = props;

	return (
		<StyledChip
	      avatar={<Avatar alt={label} src={`/img/icons/${avatar}`} />}
	      label={label}
	    />
	);
}