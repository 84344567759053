import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	textContainer: {
		marginTop: theme.spacing(3)
	},
	filtersContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		marginTop: theme.spacing(2),
		'& > div': {
			marginBottom: theme.spacing(2)
		}
	},
	CategoryChip: {
		'&.active-category': {
			color: theme.palette.secondary.main,
			backgroundColor: theme.palette.altBackground.main,
			borderColor: theme.palette.secondary.main
		}
	},
	cardsContainer: {
		marginTop: theme.spacing(2)
	},
	snackbar: {
		[theme.breakpoints.up('md')]: {
			maxWidth: '35%'
		}
	}
}));

export default useStyles;