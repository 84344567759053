import React, { useEffect } from "react";
import history from "../../history";
import { Link } from "react-router-dom";
import DrawerContainer from "../../components/DrawerContainer/DrawerContainer";
import { Grid, Typography, Button } from "@material-ui/core";
import useStyles from "./HomeStyles";
import malarkey from "malarkey";

export default function Home(props) {
  const classes = useStyles();

  useEffect(() => {
    const element = document.querySelector("#typewriter");

    function callback(text) {
      element.textContent = text;
    }

    const options = {
      typeSpeed: 70,
      deleteSpeed: 50,
      pauseDuration: 3000,
      repeat: true,
    };

    malarkey(callback, options)
      .type("Web Developer")
      .pause()
      .delete()
      .type("Frontend Developer")
      .pause()
      .delete()
      .type("Backend Developer")
      .pause()
      .delete()
      .type("UX/UI Designer")
      .pause()
      .delete();
  });

  return (
    <DrawerContainer>
      <Grid container className={classes.ContentWrapper}>
        <Grid item xs={12} md={6} className={classes.textContainer}>
          <Typography
            className={classes.greeting + " animated fadeIn"}
            component="h1"
            variant="h1"
          >
            Hi, I'm Marta,
          </Typography>
          <Typography
            component="h1"
            className={classes.title + " animated fadeIn"}
            id="typewriter"
            variant="h1"
            gutterBottom
          ></Typography>
          <div
            className={classes.mainTextContent + " animated fadeIn delay-1s"}
          >
            <Typography component="p" variant="body1" gutterBottom>
              I love building responsive and functional websites and web
              applications.
            </Typography>
            <Typography component="p" variant="body1" gutterBottom>
              Got an idea I can help you with?{" "}
              <Link to="/contact">Contact me</Link>.
            </Typography>
          </div>
          <Button
            onClick={() => history.push("/my-work")}
            className={classes.callToActionButton + " animated fadeIn delay-2s"}
            variant="outlined"
            size="large"
            color="primary"
          >
            View My Work
          </Button>
        </Grid>
        <Grid item xs={12} md={6} className={classes.picContainer}>
          <img
            className={classes.pic + " animated fadeIn"}
            src="/img/illustrations/home.svg"
            alt="Illustration of a girl sitting and holding laptop and robot, with various objects floating around them, including a lightbulb."
          />
        </Grid>
      </Grid>
    </DrawerContainer>
  );
}
