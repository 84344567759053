import { frontendSkills, backendSkills } from "./SkillList";

const projects = [
  {
    cardTitle: "Synneuro CNN",
    categories: ["React.js", "UX / UI"],
    mainImg: "synneuro.jpg",
    mainImgAlt: "Synneuro logo",
    stack: "UX/UI / React.js / Python",
    date: "Jun-Jul 2022",
    images: [
      "synneuro-landing.png",
      "synneuro-upload.png",
      "synneuro-no-tumor.png",
      "synneuro-result.png",
    ],
    title: "Synneuro Brain Tumor Detection System",
    website: "",
    company: "National College of Ireland",
    description: [
      "This project's goal is to reduce the time to diagnosis for patients with brain tumours.",
      "The project was completed within the Higher Diploma in Science in Computing program in the National College of Ireland.",
    ],
    contributions: [
      "Trained a Convolutional Neural Network model to detect whether an MRI contains tumors and classify three types of brain tumors.",
      "Created website design for desktop and mobile, implemented the app with React.js and Bootstrap.",
      "Built backend API service with Python FastAPI framework.",
    ],
    // React.js, Bootstrap, Figma, Python
    tech: [
      frontendSkills[4],
      frontendSkills[5],
      frontendSkills[9],
      backendSkills[5],
      backendSkills[7],
    ],
  },
  {
    cardTitle: "Herbody Cosmetics Website",
    categories: ["UX / UI"],
    mainImg: "herbody.png",
    mainImgAlt: "Herbody logo",
    stack: "UX/UI",
    date: "Dec 2021",
    images: [
      "herbody-home-1.png",
      "herbody-home-2.png",
      "herbody-shop.png",
      "herbody-cart.png",
      "herbody-sustainability.png",
    ],
    title: "Herbody Natural Cosmetics Website",
    company: "National College of Ireland",
    website: "https://herbody.surge.sh/",
    description: [
      "Herbody offers skin care products from organic ingredients with nourishing vitamins and oils coming in a luxury glass refillable bottles.",
      "The project was completed within the Higher Diploma in Science in Computing program in the National College of Ireland.",
    ],
    contributions: [
      "Conducted product benchmarking, created website design for desktop and mobile.",
      "Collaborated with other students on implementation of the website with Bootstrap, HTML/CSS, jQuery.",
    ],
    // Figma, Bootstrap, HTML, CSS, JavaScript
    tech: [
      frontendSkills[9],
      frontendSkills[5],
      frontendSkills[0],
      frontendSkills[1],
      frontendSkills[2],
    ],
  },
  {
    cardTitle: "Studio 27 Films Website",
    categories: ["UX / UI", "WordPress"],
    mainImg: "27-films.png",
    mainImgAlt: "Studio 27 Films Logo",
    stack: "UX/UI / WordPress",
    date: "Sep-Oct 2021",
    images: [
      "27-films-home.gif",
      "27-films-about.png",
      "27-films-services.png",
    ],
    title: "Website for Studio 27 Films",
    website: "https://www.studio27films.ie/",
    description: [
      "Studio 27 Films is a Dublin-based video production studio that specializes in producing commercials, music videos, and documentaries.",
    ],
    contributions: [
      "Designed a digital prototype of the website according to the clients' vision and implemented a custom WordPress theme.",
      "Set up a WordPress site and ensured service reliability",
      "Installed and configured required plugins, e.g. custom fields, contact forms",
    ],
    // WordPress, Bootstrap, PHP, Figma
    tech: [
      frontendSkills[8],
      frontendSkills[5],
      backendSkills[3],
      frontendSkills[9],
    ],
  },
  {
    cardTitle: "Irish Weather Telegram Bot",
    categories: ["Node.js"],
    mainImg: "weather-bot.png",
    mainImgAlt: "Sun behind the cloud icon plus Telegram messenger icon",
    stack: "UX / Node.js",
    date: "Oct 2020",
    images: [
      "weather-bot-start.png",
      "weather-bot-location.png",
      "weather-bot-forecast.png",
    ],
    title: "Irish Weather Telegram Bot",
    company: "",
    website: "",
    description: [
      "RETIRED: Unofficial Telegram bot that provides Met Éireann weather forecast for Ireland.",
    ],
    contributions: [
      "Developed the bot using Met Éireann, MapQuest and Telegram Bot APIs",
    ],
    // Node.js
    tech: [backendSkills[0]],
  },
  {
    cardTitle: "Coffee Match",
    categories: ["UX / UI"],
    mainImg: "coffee-match.png",
    mainImgAlt: "Employee Engagement Tools design",
    stack: "UX/UI",
    date: "Jun-Jul 2020",
    images: ["coffee-match-1.png", "coffee-match-2.png"],
    title: "Coffee Match App",
    company: "",
    website: "",
    description: [
      "An app that will help you find your perfect coffee beans variety that suits your taste and preferred brewing method.",
    ],
    contributions: [
      "Came up with the idea, did a research on coffee varieties and brewing methods, conceptualized the user flow.",
      "Designed digital prototypes for the intro, three questions and the final result.",
    ],
    // Figma
    tech: [frontendSkills[9]],
  },
  {
    cardTitle: "MYTH Band Website",
    categories: ["UX / UI", "WordPress"],
    mainImg: "myth.png",
    mainImgAlt: "MYTH Rock Band Logo",
    stack: "UX/UI / WordPress",
    date: "May 2020",
    images: ["myth-main.png", "myth-listen.png", "myth-about.png"],
    title: "Website for MYTH Rock Band",
    website: "https://www.mythmusicofficial.ie/",
    description: [
      "MYTH are a four-piece alternative rock band from Dublin. Their music consists of low end rhythmic harmonies, fat-back beats and haunting vocal melodies.",
    ],
    contributions: [
      "Designed a digital prototype of the website according to the band members vision and implemented a custom WordPress theme",
      "Set up a WordPress site and ensured service reliability",
      "Installed and configured required plugins, e.g. content editor, MailChipm",
    ],
    // WordPress, Bootstrap, PHP, Figma
    tech: [
      frontendSkills[8],
      frontendSkills[5],
      backendSkills[3],
      frontendSkills[9],
    ],
  },
  {
    cardTitle: "Personal Website",
    categories: ["React.js", "UX / UI"],
    mainImg: "portfolio.png",
    mainImgAlt: "This website's homepage screenshot",
    stack: "UX/UI / React.js",
    date: "Apr-May 2020",
    images: [
      "portfolio-home.png",
      "portfolio-about.png",
      "portfolio-not-found.png",
    ],
    title: "Personal Portfolio Website",
    company: "",
    website: "",
    description: ["Responsive React.js app showcasing my projects."],
    contributions: [
      "Designed a prototype in Figma and implemented as a React app",
      "Configured email sending logic with captcha verification",
    ],
    // React.js, Material UI, Figma
    tech: [frontendSkills[4], frontendSkills[6], frontendSkills[9]],
  },
  {
    cardTitle: "Dublin RTPI Telegram Bot",
    categories: ["Node.js"],
    mainImg: "rtpi-bot.png",
    mainImgAlt: "Bus icon plus Telegram messenger icon",
    stack: "UX / Node.js",
    date: "Apr 2020",
    images: ["bus-bot-start.png", "bus-bot-rtpi.png", "bus-bot-luas.png"],
    title: "Dublin RTPI Telegram Bot",
    company: "",
    website: "",
    description: [
      "IMPORTANT: RTPI Ireland API was deprecated in September 2020, the bot is no longer active.",
      "Unofficial Telegram bot to get real-time passenger information for for Dublin urban transport.",
      "Assists in looking up the stop by number or address and provides information on the next coming buses or trams.",
      "Displays stop location on the map.",
    ],
    contributions: [
      "Developed the bot using RTPI Ireland API and Telegram Bot API",
    ],
    // Node.js
    tech: [backendSkills[0]],
  },
  {
    cardTitle: "Fatigue Friend App",
    categories: ["UX / UI"],
    mainImg: "fatigue-friend.png",
    mainImgAlt: "App design",
    stack: "UX/UI",
    date: "Mar-Apr 2020",
    images: [
      "fatigue-friend-onboarding.png",
      "fatigue-friend-dashboard.png",
      "fatigue-friend-history.png",
    ],
    title: "Fatigue Friend App",
    company: "Talent Garden Innovation School",
    website: "",
    description: [
      "Fatigue Friend is a predictive tool that helps in preventing chronic fatigue for people with strokes, head injuries and other chronic conditions, reducing overtiredness and accelerating recovery.",
      "The project was completed within the UX Design Bootcamp in Talent Garden Innovation School, in collaboration with BlueBridge Technologies.",
    ],
    contributions: [
      "Conducted UX research and product benchmarking, created user personas, defined accessibility guidelines",
      "Collaborated with other UX design students on developing the designs for the app, including onboarding flow, dashboard, analytics, account settings",
    ],
    // Figma
    tech: [frontendSkills[9]],
  },
  {
    cardTitle: "Employee Engagement Tools",
    categories: ["UX / UI"],
    mainImg: "employee-dashboard.png",
    mainImgAlt: "Employee Engagement Tools design",
    stack: "UX/UI",
    date: "Feb-Mar 2020",
    images: [
      "empl-dashboard-1.png",
      "empl-dashboard-2.png",
      "empl-dashboard-3.png",
      "empl-dashboard-4.png",
    ],
    title: "Employee Engagement Tools",
    company: "Talent Garden Innovation School",
    website: "",
    description: [
      "Digital tools that help companies in addressing job satisfaction issues through involving employees in decision making process to eventually form a better organizational culture.",
      "The project was completed within the UX Design Bootcamp in Talent Garden Innovation School.",
    ],
    contributions: [
      "Conducted UX interviews and product benchmarking, mapped stakeholder journeys, carried out usability testing",
      "Designed digital prototypes for the three sections of the employee tools: Annual Report, Action Plans and Trends Overview",
    ],
    // Figma
    tech: [frontendSkills[9]],
  },
  {
    cardTitle: "Inclusive Hubs Playbook",
    categories: ["WordPress"],
    mainImg: "inclusive-hubs.png",
    mainImgAlt: "Inclusive Hubs Playbook hopepage screenshot",
    stack: "WordPress",
    date: "Jul-Sep 2019",
    images: ["playbook-home.png", "playbook-subsection.png"],
    title: "Website for Inclusive Hubs Playbook",
    company: "Dogpatch Labs, Google for Startups",
    website: "https://www.inclusivehubs.com/",
    description: [
      "Inclusive Hubs Playbook is a practical guide to help anyone leading or working in startup hubs to foster a diverse and inclusive startup community.",
    ],
    contributions: [
      "Set up a WordPress site for the Playbook and ensured service reliability",
      "Created a custom WP theme following brand guidelines and UI prototypes",
      "Installed and configured required plugins, e.g. sidebar menu, Atomic Blocks, cookie notice",
    ],
    // WordPress, Bootstrap
    tech: [frontendSkills[8], frontendSkills[5]],
  },
];

export default projects;
