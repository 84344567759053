import React from "react";
import history from "../../history";
import DrawerContainer from "../../components/DrawerContainer/DrawerContainer";
import { Grid, Typography, Button } from "@material-ui/core";
import useStyles from "./NotFoundStyles";

export default function NotFound(props) {
  const classes = useStyles();

  return (
    <DrawerContainer>
      <Grid container className={classes.ContentWrapper}>
        <Grid item xs={12} md={5} className={classes.textContainer}>
          <Typography component="h1" variant="h1" gutterBottom>
            Oh, no!
          </Typography>
          <div className={classes.mainTextContent}>
            <Typography component="p" variant="body1" gutterBottom>
              The page you’re trying to reach doesn’t exist.
            </Typography>
            <Typography component="p" variant="body1" gutterBottom>
              Try clicking some buttons in the sidebar or the one below.
            </Typography>
          </div>
          <Button
            onClick={() => history.push("/")}
            className={classes.callToActionButton}
            variant="outlined"
            size="large"
            color="primary"
          >
            Go To Homepage
          </Button>
        </Grid>
        <Grid item xs={12} md={7} className={classes.picContainer}>
          <img
            className={classes.pic + " animated fadeIn"}
            src="/img/illustrations/not-found.svg"
            alt="Illustration of a person in space suit floating in space with a cat and planets around them."
          />
        </Grid>
      </Grid>
    </DrawerContainer>
  );
}
