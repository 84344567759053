import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	dialogContainer: {
		backgroundColor: theme.palette.altBackground.main
	},
	dialogContent: {
		marginTop: theme.spacing(3)
	},
	subtitle: {
		marginTop: theme.spacing(3)
	},
	contributionsList: {
		color: theme.palette.text.main,
		paddingLeft: theme.spacing(2)
	},
	skillList: {
		display: 'flex',
	    flexWrap: 'wrap',
	    listStyle: 'none',
	    padding: 0,
	    marginBottom: 0,
	    '& > li': {
	    	marginRight: theme.spacing(2),
	    	marginBottom: theme.spacing(1.5)
	    }
	},
}));

export default useStyles;