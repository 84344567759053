import React, { useState } from 'react';
import history from '../../history';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Drawer, List, ListItem, Typography, ListItemText, ListItemIcon, AppBar, Toolbar, IconButton, Container } from '@material-ui/core';
import useStyles from './DrawerContainerStyles';
import MenuIcon from '@material-ui/icons/Menu';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';

export default function DrawerContainer(props) {
	const classes = useStyles();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [hovered, setHovered] = useState([false, false, false, false]);

	function toggleHover(idx, value) {
		let hoveredCopy = [...hovered];
		hoveredCopy[idx] = value;
		setHovered(hoveredCopy);
	}

	function menuList(mobile) {
		return (
			<React.Fragment>
				{ mobile ? null :
				<List>
        			<ListItem 
        				button 
        				onClick={() => history.push("/")}>
		              <ListItemIcon>
		              	<img 
		              	  className={classes.icon} 
		              	  alt="Website logo in form of HTML tag" 
		              	  src="/img/icons/coding.svg" />
		              </ListItemIcon>
		            </ListItem>
		        </List> }
		        <List>
        			<ListItem 
        			  button 
        			  onClick={() => history.push("/")} 
        			  selected={history.location.pathname === "/"} 
        			  className={classes.menuListItem}
        			  onMouseOver={() => toggleHover(0, true)}
        			  onMouseOut={() => toggleHover(0, false)}>
		              <ListItemIcon className={classes.iconButton}>
		              	{ !mobile && hovered[0] === true ?
		              	  <Typography color="primary" className={classes.iconText} component="span" variant="body2">HOME</Typography>
		              	: <HomeOutlinedIcon /> }
		              </ListItemIcon>
		              { mobile ? <ListItemText primary="Home" className={classes.mobileListItemText} /> : null }
		            </ListItem>
		            <ListItem 
		              button 
		              onClick={() => history.push("/about")} 
		              selected={history.location.pathname === "/about"} 
		              className={classes.menuListItem}
		              onMouseOver={() => toggleHover(1, true)}
        			  onMouseOut={() => toggleHover(1, false)}>
		              <ListItemIcon className={classes.iconButton}>
		              	{ !mobile && hovered[1] === true ?
		              	  <Typography color="primary" className={classes.iconText} component="span" variant="body2">ABOUT</Typography>
		              	: <PersonOutlineOutlinedIcon /> }
		              </ListItemIcon>
		              { mobile ? <ListItemText primary="About me" className={classes.mobileListItemText} /> : null }
		            </ListItem>
		            <ListItem 
		              button 
		              onClick={() => history.push("/my-work")} 
		              selected={history.location.pathname === "/my-work"} 
		              className={classes.menuListItem}
		              onMouseOver={() => toggleHover(2, true)}
        			  onMouseOut={() => toggleHover(2, false)}>
		              <ListItemIcon className={classes.iconButton}>
		              	{ !mobile && hovered[2] === true ?
		              	  <Typography color="primary" className={classes.iconText} component="span" variant="body2">WORK</Typography>
		              	: <AppsOutlinedIcon /> }
		              </ListItemIcon>
		              { mobile ? <ListItemText primary="My Work" className={classes.mobileListItemText} /> : null }
		            </ListItem>
		            <ListItem 
		              button 
		              onClick={() => history.push("/contact")} 
		              selected={history.location.pathname === "/contact"} 
		              className={classes.menuListItem}
		              onMouseOver={() => toggleHover(3, true)}
        			  onMouseOut={() => toggleHover(3, false)}>
		              <ListItemIcon className={classes.iconButton}>
		              	{ !mobile && hovered[3] === true ?
		              	  <Typography color="primary" className={classes.iconText} component="span" variant="body2">CONTACT</Typography>
		              	: <MailOutlinedIcon /> }
		              </ListItemIcon>
		              { mobile ? <ListItemText primary="Contact" className={classes.mobileListItemText} /> : null }
		            </ListItem>
		        </List>
		        { !mobile ? 
			        <List>
	        			<ListItem 
	        			  button 
	        			  onClick={() => window.open("https://github.com/kabeiro")}>
			              <ListItemIcon>
			              	<img 
			              	  className={classes.iconLogo} 
			              	  alt="Github logo" 
			              	  src="/img/icons/github.svg" />
			              </ListItemIcon>
			              { mobile ? <ListItemText primary="GitHub" className={classes.mobileListItemText} /> : null }
			            </ListItem>
			            <ListItem 
			              button 
			              onClick={() => window.open("https://www.linkedin.com/in/marta-semeniuk/")}>
			              <ListItemIcon>
			              	<img 
			              	  className={classes.iconLogo} 
			              	  alt="LinkedIn logo" 
			              	  src="/img/icons/linkedin.svg" />
			              </ListItemIcon>
			              { mobile ? <ListItemText primary="LinkedIn" className={classes.mobileListItemText} /> : null }
			            </ListItem>
			        </List> : null }
			</React.Fragment>
		);
	}

	return (
		<div>
			<CssBaseline />
			<AppBar
		        position="fixed"
		     	className={classes.topNavbar}
		      >
		        <Toolbar className={classes.toolBar}>
		          <IconButton
		            color="inherit"
		            aria-label="open drawer"
		            onClick={() => setDrawerOpen(true)}
		            edge="start"
		            
		          >
		            <MenuIcon />
		          </IconButton>
		          <img className={classes.icon} alt="Website logo in form of HTML tag" src="/img/icons/coding.svg" />
		        </Toolbar>
		      </AppBar>
		      <Drawer anchor='top' className={classes.topDrawer} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                {menuList(true)}
            </Drawer>
			<Drawer
	          variant="permanent"
	          className={classes.drawer}>
        		{ menuList(false) }
        	</Drawer>
			<main className={classes.main}>
				<Container>
					{props.children}
				</Container>
			</main>
		</div>
	);
}