import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	ContentWrapper: {
		[theme.breakpoints.up('sm')]: {
			minHeight: "100vh"
		}
	},
	textContainer: {
		paddingLeft: theme.spacing(3),
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(6)
		}
	},
	mainTextContent: {
		marginBottom: theme.spacing(4),
		'& > p': {
			marginBottom: theme.spacing(2)
		}
	},
	input: {
		width: '95%',
		marginTop: theme.spacing(2)
	},
	inputLabel: {
		color: theme.palette.light.main
	},
	submitBtn: {
		display: 'block',
		marginTop: theme.spacing(2)
	},
	picContainer: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			marginTop: "5%",
			display: 'block',
			alignSelf: "flex-end",
			paddingBottom: "5%",
		}
	},
	snackbar: {
		[theme.breakpoints.up('md')]: {
			maxWidth: '35%'
		},
		'&.success > div.MuiPaper-root': {
			backgroundColor: theme.palette.primary.light
		},
		'&.error > div.MuiPaper-root': {
			backgroundColor: theme.palette.secondary.light
		},
	}
}));

export default useStyles;