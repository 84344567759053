import { createMuiTheme } from '@material-ui/core/styles';

const colors = {
	darkCyan: '#011627',
	cyanBlue: '#75BEFF',
	redOrange: '#FA8C66',
	mist: '#DADFE0',
	sunsetPearl: '#F6BE81',
	inchworm: '#A8DF67',
	downy: '#70CAB9'
}

const theme = createMuiTheme({
	palette: {
		type: 'dark',
		background: {
			default: colors.darkCyan
		},
		primary: {
	      main: colors.cyanBlue,
	    },
	    secondary: {
	    	main: colors.redOrange
	    },
	    light: {
	    	main: colors.mist
	    },
	    altBackground: {
	    	main: '#0B253A'
	    },
	    text: {
	    	main: colors.inchworm
	    }
	},
	typography: {
	    fontFamily: 'Fira Code',
	    fontSize: 14,
	    h1: {
	    	fontSize: '2.5rem',
	    	color: colors.sunsetPearl
	    },
	    h2: {
	    	fontSize: '1.5rem',
	    	color: colors.mist
	    },
	    h3: {
	    	fontSize: '1.3rem',
	    	color: colors.mist
	    },
	    h4: {
			fontSize: '1.2rem',
	    	color: colors.mist
	    },
	    subtitle1: {
	    	fontSize: '1.1rem',
	    	color: colors.downy
	    },
	    body1: {
	    	color: colors.inchworm
	    },
	    body2: {
	    	color: colors.inchworm
	    },
	    caption: {
	    	color: colors.downy
	    }
	},
});

export default theme;