import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	ContentWrapper: {
		minHeight: "100vh"
	},
	greeting: {
		color: theme.palette.light.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem'
		}
	},
	title: {
		minHeight: '46.5px',
		[theme.breakpoints.down('sm')]: {
			minHeight: '33px',
			fontSize: '1.8rem'
		}
	},
	textContainer: {
		paddingLeft: theme.spacing(3),
		marginTop: theme.spacing(3),
		[theme.breakpoints.up('sm')]: {
			marginTop: "15%"
		}
	},
	mainTextContent: {
		maxWidth: '425px'
	},
	callToActionButton: {
		marginTop: theme.spacing(2)
	},
	picContainer: {
		alignSelf: "flex-end",
		paddingBottom: "3%",
		[theme.breakpoints.down('md')]: {
			marginTop: "4%"
		}
	},
	pic: {
		maxWidth: "100%"
	}
}));

export default useStyles;