import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    backgroundColor: theme.palette.altBackground.main,
    margin: '0 auto',
    border: '1px solid rgba(255, 255, 255, 0.12)'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  cardActionsArea: {
    justifyContent: 'space-between'
  }
}));

export default function ProjectCard(props) {
  const classes = useStyles();
  const { img, imgAlt, title, stack, handleClick, date } = props;

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          className={classes.media}
          image={`/img/work/cards/${img}`}
          title={imgAlt}
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" component="p">
            {stack}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActionsArea}>
        <Button size="small" color="primary" onClick={handleClick}>
          Learn More
        </Button>
        <Typography variant="caption" component="p">
          {date}
        </Typography>
      </CardActions>
    </Card>
  );
}