import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	ContentWrapper: {
		[theme.breakpoints.up('sm')]: {
			minHeight: "100vh"
		}
	},
	textContainer: {
		paddingLeft: theme.spacing(3),
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			marginTop: theme.spacing(5)
		}
	},
	mainTextContent: {
		marginBottom: theme.spacing(4),
		'& > p': {
			marginBottom: theme.spacing(2)
		}
	},
	skillList: {
		display: 'flex',
	    flexWrap: 'wrap',
	    listStyle: 'none',
	    padding: 0,
	    '& > li': {
	    	marginRight: theme.spacing(2),
	    	marginBottom: theme.spacing(1.5)
	    }
	},
	skillChip: {
		backgroundColor: theme.palette.altBackground.main,
		border: '1px solid rgba(255, 255, 255, 0.12)',
		alignItems: 'center'
	},
	picContainer: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			marginTop: "5%",
			display: 'block',
			alignSelf: "flex-end",
			paddingBottom: "5%",
		}
	},
	pic: {
		maxWidth: "100%"
	}
}));

export default useStyles;