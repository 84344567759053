import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import DrawerContainer from "../../components/DrawerContainer/DrawerContainer";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import useStyles from "./ContactStyles";
import lottie from "lottie-web";
import emailjs from "emailjs-com";

export default function Contact(props) {
  const classes = useStyles();
  const recaptchaRef = React.createRef();
  const [openSnackbar, setSnackbarOpen] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("Send");

  useEffect(() => {
    let el = document.getElementById("lottie");
    lottie.loadAnimation({
      container: el,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/img/illustrations/contact.json",
    });
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setBtnDisabled(true);
    setBtnText("Sending...");
    recaptchaRef.current.execute();
  }

  function handleCaptcha(val) {
    emailjs
      .sendForm(
        "gmail",
        "template_81Au0Oy2",
        "#contact-form",
        "user_7ONZinTkn52GBQZMZujzW"
      )
      .then(
        (result) => {
          setBtnText("Sent!");
          setBtnDisabled(false);
          setSnackbarOpen({
            open: true,
            type: "success",
            message: "Thank you! Your message was sent.",
          });
        },
        (error) => {
          setBtnText("Failed");
          setBtnDisabled(false);
          setSnackbarOpen({
            open: true,
            type: "error",
            message:
              "Oops, there was a problem sending the message. Please check your information and try again.",
          });
        }
      );
  }

  function handleCaptchaError(err) {
    setBtnText("Failed");
    setBtnDisabled(false);
    setSnackbarOpen({
      open: true,
      type: "error",
      message:
        "Oops, there was a problem verifying ReCAPTCHA. Please try to reload the page and send the message again.",
    });
  }

  return (
    <DrawerContainer>
      <Grid container className={classes.ContentWrapper}>
        <Grid item xs={12} md={4} className={classes.textContainer}>
          <Typography
            className="animated fadeIn"
            component="h1"
            variant="h1"
            gutterBottom
          >
            Contact Me
          </Typography>
          <div className="animated fadeIn">
            <Typography component="p" variant="body1">
              Got an idea I can help you with?
            </Typography>
            <Typography component="p" variant="body1">
              I'll be happy to hear from you!
            </Typography>
            <Typography component="p" variant="body1" gutterBottom>
              Also, feel free to add me on{" "}
              <a
                href="https://www.linkedin.com/in/marta-semeniuk/"
                target="_bland"
                rel="noopenner"
              >
                LinkedIn
              </a>
            </Typography>
          </div>
          <form
            autoComplete="off"
            id="contact-form"
            className="animated fadeIn delay-1s"
            onSubmit={handleSubmit}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6LcQ0vIUAAAAACjYw6jRpK-Uvf_vcl4YS0hT4YlJ"
              onChange={handleCaptcha}
              onErrored={handleCaptchaError}
            />
            <TextField
              required
              name="from_name"
              InputLabelProps={{ className: classes.inputLabel }}
              className={classes.input}
              label="Your Name"
              variant="outlined"
            />
            <TextField
              required
              name="reply_to"
              InputLabelProps={{ className: classes.inputLabel }}
              className={classes.input}
              type="email"
              label="Your Email"
              variant="outlined"
            />
            <TextField
              required
              InputLabelProps={{ className: classes.inputLabel }}
              className={classes.input}
              name="message"
              label="Message"
              multiline
              rows={6}
              variant="outlined"
            />
            <Button
              className={classes.submitBtn}
              size="large"
              type="submit"
              onSubmit={handleSubmit}
              variant="contained"
              color="primary"
              disabled={btnDisabled}
            >
              {btnText}
            </Button>
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          className={classes.picContainer + " animated fadeIn delay-1s"}
        >
          <div id="lottie"></div>
        </Grid>
      </Grid>

      <Snackbar
        className={
          classes.snackbar +
          " " +
          (openSnackbar.type === "success" ? "success" : "error")
        }
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        open={openSnackbar.open}
        autoHideDuration={6000}
        onClose={() =>
          setSnackbarOpen((prevState) => ({ ...prevState, open: false }))
        }
        message={openSnackbar.message}
      />
    </DrawerContainer>
  );
}
