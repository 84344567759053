import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	topNavbar: {
		color: "white",
		backgroundColor: "#001122!important",
		borderBottom: '1px solid #0A2239!important',
		[theme.breakpoints.up('sm')]: {
			display: "none"
		}
	},
	toolBar: {
		display: "flex",
		justifyContent: "space-between"
	},
	topDrawer: {
		'& > div.MuiPaper-root': {
			backgroundColor: '#001122!important'
		},
		'& > div.MuiDrawer-paperAnchorTop': {
			maxHeight: '60vh!important',
			height: 'auto!important'
		}
	},
	drawer: {
		display: "none",
		[theme.breakpoints.up('sm')]: {
	      display: "flex"
	    },
		flexShrink: 0,
		whiteSpace: 'nowrap',
		'& > div.MuiPaper-root': {
			backgroundColor: '#001122!important',
			width: 73,
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
		},
		'& > div.MuiDrawer-paperAnchorDockedLeft': {
			borderRight: '1px solid #0A2239!important'
		}
	},
	icon: {
		width: "31px"
	},
	iconLogo: {
		width: "25px",
		paddingLeft: "6px"
	},
	iconButton: {
		color: theme.palette.primary.main,
		width: "31px",
		height: '24px'
	},
	menuListItem: {
		'& > *': {
			pointerEvents: "none!important"
		},
		'&.Mui-selected > div.MuiListItemIcon-root': {
			color: theme.palette.secondary.main + '!important',
			'& > span.MuiTypography-root': {
				color: theme.palette.secondary.main + '!important'
			}
		}
	},
	iconText: {
		margin: 'auto -5px',
		fontSize: '0.8rem'
	},
	mobileListItemText: {
		'& > span.MuiTypography-root': {
			color: theme.palette.light.main + '!important'
		}
	},
	main: {
		marginTop: "56px",
		[theme.breakpoints.up('sm')]: {
	      marginTop: 0,
	      marginLeft: 73
	    }
	}
}));

export default useStyles;