import React from 'react';
import { Button, Box, Dialog, DialogActions, DialogContent, Typography, useMediaQuery } from '@material-ui/core';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import useStyles from './ProjectDialogStyles';
import { useTheme } from '@material-ui/core/styles';
import SkillChip from '../SkillChip/SkillChip';

export default function ProjectDialog (props) {
	const classes = useStyles();
	const { open, handleClose, item } = props;
	const images = item.images !== undefined && item.images.length > 0 ? item.images.map(img => ({ 
		  original: `/img/work/modals/${img}`
		})) : [];
	
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	
	return (	
		<Dialog
			open={open}
			maxWidth="md"
			fullScreen={fullScreen}
	        onClose={handleClose}
	        scroll='body'
	        aria-labelledby="project-dialog-title"
	        aria-describedby="project-dialog-description"
	        PaperProps={{className: classes.dialogContainer}}>
	      	<ImageGallery 
	      	  items={images}
	      	  showThumbnails={false}
	      	  showPlayButton={false}
	      	  showBullets={true}
	      	  showFullscreenButton={false}
	      	/>
	        <DialogContent className={classes.dialogContent}>
	        	<Typography 
				  component="p" 
				  variant="h3"
				  gutterBottom>
					<strong>{item.title}</strong>
				</Typography>
	        	<Typography 
				  component="p" 
				  variant="subtitle1"
				  gutterBottom>
					{ item.company && item.company.length > 0 ? item.company + ', ' + item.date : item.date }
				</Typography>
				{ item.description.map((pg, idx) => (
					<Typography 
					  key={idx}
					  component="p" 
					  variant="body1"
					  gutterBottom>
						{ pg }
					</Typography>
				))}
				<Typography 
				  component="p" 
				  variant="h4"
				  className={classes.subtitle}
				  gutterBottom>
					<strong>My Contributions</strong>
				</Typography>
				<ul className={classes.contributionsList}>
					{item.contributions.map((contribution, idx) => (
						<li key={idx}>
							<Typography 
							  component="p" 
							  variant="body1">
								{ contribution }
							</Typography>
						</li>
					))}
				</ul>
				<Typography 
				  component="p" 
				  variant="h4"
				  className={classes.subtitle}
				  gutterBottom>
					<strong>Technologies and Tools</strong>
				</Typography>
				<Box component='ul' className={classes.skillList}>
					{ item.tech.map((skill, idx) => (
						<li key={idx}>
							<SkillChip
						      avatar={skill.avatar}
						      label={skill.label}
						    />
						</li>
					))}
				</Box>
	        </DialogContent>
	        <DialogActions>
	          { item.website.length > 0 ?
	          <Button onClick={() => window.open(item.website)} color="primary">
	            Visit Website
	          </Button> : null }
	          <Button onClick={handleClose} color="primary">
	            Close
	          </Button>
	        </DialogActions>
	    </Dialog>
	);
}