import React from "react";
import { Link } from "react-router-dom";
import DrawerContainer from "../../components/DrawerContainer/DrawerContainer";
import { Grid, Typography, Box } from "@material-ui/core";
import useStyles from "./AboutStyles";
import { frontendSkills, backendSkills } from "../../data/SkillList";
import SkillChip from "../../components/SkillChip/SkillChip";

export default function About() {
  const classes = useStyles();

  return (
    <DrawerContainer>
      <Grid container className={classes.ContentWrapper}>
        <Grid item xs={12} md={6} className={classes.textContainer}>
          <div className="animated fadeIn">
            <Typography component="h1" variant="h1" gutterBottom>
              About Me
            </Typography>
            <div className={classes.mainTextContent}>
              <Typography component="p" variant="body1">
                I’m a full-stack web developer and UX/UI enthusiast, living in
                Dublin, Ireland.
              </Typography>
              <Typography component="p" variant="body1">
                I’m passionate about building intuitive and accessible web
                interfaces, while writing clean, maintainable code.
              </Typography>
              <Typography component="p" variant="body1">
                I have done work in front-end and back-end web development,
                automation testing, built WordPress websites and Machine
                Learning projects.
              </Typography>
            </div>
          </div>
          <div className="animated fadeIn">
            <Typography component="h1" variant="h1" gutterBottom>
              Skills
            </Typography>
            <Typography component="h2" variant="h2" gutterBottom>
              Front-End
            </Typography>
            <Box component="ul" className={classes.skillList}>
              {frontendSkills.map((skill) => (
                <li key={skill.key}>
                  <SkillChip avatar={skill.avatar} label={skill.label} />
                </li>
              ))}
            </Box>
            <Typography component="h2" variant="h2" gutterBottom>
              Back-End &amp; AI/ML
            </Typography>
            <Box component="ul" className={classes.skillList}>
              {backendSkills.map((skill) => (
                <li key={skill.key}>
                  <SkillChip avatar={skill.avatar} label={skill.label} />
                </li>
              ))}
            </Box>
            <Typography component="p" variant="body1">
              Have a question or would like to work together?{" "}
              <Link to="/contact">Contact me</Link>.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.picContainer}>
          <img
            className={classes.pic + " animated fadeIn"}
            src="/img/illustrations/about.svg"
            alt="Illustration of a person at the table with laptop and two monitors writing code, with papers floating around."
          />
        </Grid>
      </Grid>
    </DrawerContainer>
  );
}
