import React, { useEffect, useState } from "react";
import DrawerContainer from "../../components/DrawerContainer/DrawerContainer";
import { Grid, Typography, Box, Chip, Snackbar } from "@material-ui/core";
import useStyles from "./WorkStyles";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import projects from "../../data/projects";
import ProjectDialog from "../../components/ProjectDialog/ProjectDialog";

export default function Work(props) {
  const classes = useStyles();
  const categories = [
    "Show all",
    "React.js",
    "Node.js",
    "UX / UI",
    "WordPress",
  ];
  const [currentCategory, setCurrentCategory] = useState("Show all");
  const [openSnackbar, setSnackbarOpen] = useState(false);
  const [openModal, setModalOpen] = useState(false);
  const [currentProj, setCurrentProj] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSnackbarOpen(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  function handleCategoryChoice(event, category) {
    setCurrentCategory(category);

    let activeCategories = document.querySelectorAll(".active-category");
    for (let el of activeCategories) {
      el.classList.remove("active-category");
    }

    event.target.tagName === "SPAN"
      ? event.target.parentNode.classList.add("active-category")
      : event.target.classList.add("active-category");
  }

  function handleCardClick(event, idx) {
    setCurrentProj(idx);
    setModalOpen(true);
  }

  return (
    <DrawerContainer>
      <Grid container justify="center">
        <Grid item xs={12} className={classes.textContainer}>
          <Typography
            component="h1"
            variant="h1"
            align="center"
            className="animated fadeIn"
            gutterBottom
          >
            My Projects
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={11} md={6} lg={5}>
              <Box className={classes.filtersContainer}>
                {categories.map((category, idx) => (
                  <Chip
                    key={idx}
                    clickable
                    variant="outlined"
                    onClick={(e) => handleCategoryChoice(e, category)}
                    color="primary"
                    label={category}
                    className={
                      classes.CategoryChip +
                      (category === "Show all" ? " active-category" : "")
                    }
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.cardsContainer}>
          <Grid container justify="center" spacing={3}>
            {projects.map((project, idx) => {
              return project.categories.includes(currentCategory) ||
                currentCategory === "Show all" ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  key={idx}
                  className="animated fadeIn"
                >
                  <ProjectCard
                    img={project.mainImg}
                    imgAlt={project.mainImgAlt}
                    title={project.cardTitle}
                    stack={project.stack}
                    date={project.date}
                    handleClick={(e) => handleCardClick(e, idx)}
                  />
                </Grid>
              ) : null;
            })}
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={() => setSnackbarOpen(false)}
        message="Please note, unless the project has a UX/UI tag, the design isn't mine, just the code."
      />
      {openModal ? (
        <ProjectDialog
          open={openModal}
          handleClose={() => setModalOpen(false)}
          item={projects[currentProj]}
        />
      ) : null}
    </DrawerContainer>
  );
}
