import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	ContentWrapper: {
		[theme.breakpoints.up('sm')]: {
			minHeight: "100vh"
		}
	},
	textContainer: {
		paddingLeft: theme.spacing(3),
		marginTop: theme.spacing(3),
		[theme.breakpoints.up('sm')]: {
			marginTop: "15%"
		}
	},
	mainTextContent: {
		maxWidth: '425px'
	},
	callToActionButton: {
		marginTop: theme.spacing(2)
	},
	picContainer: {
		alignSelf: "flex-end",
		paddingBottom: "3%",
		[theme.breakpoints.down('md')]: {
			marginTop: "10%"
		}
	},
	pic: {
		maxWidth: "100%"
	}
}));

export default useStyles;