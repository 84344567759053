const frontendSkills = [
  {
    key: 0,
    label: "HTML",
    avatar: "html.svg",
  },
  {
    key: 1,
    label: "CSS",
    avatar: "css.svg",
  },
  {
    key: 2,
    label: "JavaScript",
    avatar: "javascript.svg",
  },
  {
    key: 3,
    label: "TypeScript",
    avatar: "typescript.svg",
  },
  {
    key: 4,
    label: "React.js",
    avatar: "react.svg",
  },
  {
    key: 5,
    label: "Bootstrap",
    avatar: "bootstrap.png",
  },
  {
    key: 6,
    label: "Material UI",
    avatar: "materialui.png",
  },
  {
    key: 7,
    label: "Redux",
    avatar: "redux.png",
  },
  {
    key: 8,
    label: "WordPress",
    avatar: "wordpress.png",
  },
  {
    key: 9,
    label: "Figma",
    avatar: "figma.png",
  },
];

const backendSkills = [
  {
    key: 0,
    label: "Node.js",
    avatar: "nodejs.svg",
  },
  {
    key: 1,
    label: "MongoDB",
    avatar: "mongodb.png",
  },
  {
    key: 2,
    label: "AWS",
    avatar: "aws.svg",
  },
  {
    key: 3,
    label: "PHP",
    avatar: "php.svg",
  },
  {
    key: 4,
    label: "MySQL",
    avatar: "mysql.png",
  },
  {
    key: 5,
    label: "Python",
    avatar: "python.png",
  },
  {
    key: 6,
    label: "scikit-learn",
    avatar: "scikit.png",
  },
  {
    key: 7,
    label: "TensorFlow",
    avatar: "tensorflow.svg",
  },
];

export { frontendSkills, backendSkills };
