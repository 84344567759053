import React from 'react';
import history from './history';
import {
  Router,
  Switch,
  Route
} from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import Home from './containers/Home/Home';
import About from './containers/About/About';
import Work from './containers/Work/Work';
import Contact from './containers/Contact/Contact';
import NotFound from './containers/NotFound/NotFound';

function App() {
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/my-work" component={Work} />
          <Route exact path="/contact" component={Contact} />
          <Route component={NotFound} />
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
